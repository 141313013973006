<template>

  <div id="main-wrapper">
    <vcl-facebook :rtl="true" v-if="!pageloading" ></vcl-facebook>
    <vcl-instagram :rtl="true" v-if="!pageloading"></vcl-instagram>
    <vcl-facebook :rtl="true" v-if="!pageloading" ></vcl-facebook>

    <div v-else class="main-content is-footer">
      <!--پرفروشترین ها--->
      <v-container >

        <v-row style="width: 100%;background: #eee;border-radius: 15px;margin: auto">
          <v-col >
            <p class="text-center font-medium font-weight-bold pt-3 loading-item" style="height: 100%"><v-icon color="black" size="27">mdi-seal</v-icon> قسمت مربوط به ویرایش اطلاعات شما </p>
          </v-col>
        </v-row>

        <v-col cols="12">
          <div  class="text-center" >

            <p style="height: 100%"> شماره کارت باید انگلیسی و بدون خط تیره یا فاصله باشد این کارت برای واریز جایزه است </p>
            <v-text-field type="number"  v-model="sh_card" outlined label="شماره کارت برای واریز جایزه به شما "  ></v-text-field>
            <p style="height: 100%"> شماره شبا انگلیسی و بدون خط تیره یا فاصله باشد و فقط ۲۴ عدد بدون IR باشد این شبا برای واریز جایزه است </p>
            <v-text-field type="number" v-model="sheba" outlined label="شماره شبا برای واریز جایزه به شما را وارد کنید"  ></v-text-field>
            <p style="height: 100%"> برای تایید ویرایش باید رمز عبور فعلی خودتان را وارد کنید </p>
            <v-text-field v-model="password" outlined label="رمز عبور فعلی"  ></v-text-field>

            <p style="height: 100%">
              <v-icon color="black" size="27">mdi-seal</v-icon>
              اگر میخواهید رمز عبورتان تغییر کند رمز عبور جدید را وارد کنید
            </p>

            <v-text-field v-model="passwordnew" outlined label="رمز عبور جدید اگر میخواهید تغییر کند رمزتان"  ></v-text-field>


            <v-row class="pr-4 pl-4 pt-5" style="width: 99%;margin: auto;padding-bottom: 1vh" >
              <v-col cols="12" class="text-center">
                <v-btn rounded @click="updateprofile" :loading="loading" depressed color="primary" height="50" style="width: 100%"> ویرایش مشخصات </v-btn>
              </v-col>
              <v-col cols="12" class="text-center" >
                <v-btn rounded @click="$router.push({path:'/profile'})" :loading="loading" depressed color="primary" height="50" style="width: 100%"> لغو ویرایش </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>



      </v-container>


    </div>
    <app-footer></app-footer>
   </div>
</template>


<script>


import VuePictureSwipe from 'vue-picture-swipe';
import {mapGetters} from 'vuex'
import appFooter from '../views/_footer'
import axios from "axios";
import { VclFacebook, VclInstagram } from 'vue-content-loading';
import Uploader from "vux-uploader-component";


export default {
  name: 'problemedit',
  components: {
    VuePictureSwipe,
    VclFacebook,
    VclInstagram,
    Uploader,
    appFooter,
  },
  data () {
    return {
      show: false,
      loading:false,
      url:'http://g.samanyaar.ir/soccerp/',
      sh_card:'',
      password:'',
      passwordnew:'',
      sheba:'',
      pageloading:false,

    }
  },
  mounted() {
    let self = this;

    self.getvar();

  },
  computed:{
    ...mapGetters(['baseUrl','baseUrlproblem',"auth"]),
  },
  methods:{
    randomColor() {
      const r = () => Math.floor(150 * Math.random());

      return  `rgb(${r()}, ${r()}, ${r()})`;
    },

    getvar(){
      let self = this;
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }

      let formData = new FormData()
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);

      axios.post(this.baseUrl+'/profile.aspx', formData, config)
          .then(function (response) {
            if(response.data.data.result=="1"){
              self.sh_card=response.data.data.Shhesab;
              self.sheba=response.data.data.daten;
            }else{
              self.helperToast({
                text:response.data.data.message,
                color:'red',
                show:true,
              })
            }
            self.pageloading=true;
          })
    },
    validatesheba(sheba) {
      if (/^(?=.{24}$)[0-9]*$/i.test(sheba)) {
        return true;
      }
      else
        return false;
      },
     validateCard(card) {
     if (typeof card === 'undefined'
      || card === null
      || card.length !== 16) {
       return false;
     }
     let cardTotal = 0;
     for (let i = 0; i < 16; i += 1) {
     const c = Number(card[i]);
     if (i % 2 === 0) {
      cardTotal += ((c * 2 > 9) ? (c * 2) - 9 : (c * 2));
     } else {
      cardTotal += c;
     }
   }
   return (cardTotal % 10 === 0);
   },
    updateprofile(){
      let self = this;
        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }
        if(!self.validatesheba(self.sheba))
        {
          self.helperToast({
            text:"شبایی که ثبت کردید صحیح نیست",
            color:'red',
            show:true,
          });
          return false;
        }
      if(!self.validateCard(self.sh_card))
      {
        self.helperToast({
          text:"کارتی که ثبت کردید صحیح نیست",
          color:'red',
          show:true,
        });
        return false;
      }
      if(self.password.length > 5)
      {
        if (self.validateCard(self.sh_card) && self.validatesheba(self.sheba))
        {
          let formData = new FormData()
          formData.append("token", self.appToken);
          formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
          formData.append("sh_card", self.sh_card);
          formData.append('sheba', self.sheba);
          formData.append('password', self.password);
          if(self.passwordnew.length > 0 )
            formData.append('passwordnew', self.passwordnew);
          else
            formData.append('passwordnew', 'no');

          axios.post(this.baseUrl+'/registeredits.aspx', formData, config)
              .then(function (response) {
                if(response.data.data.result=="1"){
                  //console.log(response.data);
                  self.helperToast({
                    text:"ویرایش با موفقیت انجام شد",
                    color:'green',
                    show:true,
                  });
                  self.$router.push({path:'/profile'});
                }else{
                  self.helperToast({
                    text:response.data.data.result,
                    color:'red',
                    show:true,
                  })
                }
              })
              .catch(function (error) {
                self.output = error;
              });
        }
      }
      else
      {
        self.helperToast({
          text:"رمز عبور باید حداقل ۶ کلمه ای باشد",
          color:'red',
          show:true,
        })
      }




    },


  },
  watch:{

  }
}
</script>

<style>
.border-grid .col{
  height: 100px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid .col:nth-child(3n + 0){
  border-left: none;
}
.border-grid .col:nth-last-child(-n + 3){
  border-bottom: none;
}
.border-grid .col p{
  font-weight: 600;
}

.border-grid2 .col{
  height: 300px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid2 .col:nth-child(2n + 0){
  border-left: none;
}
.border-grid2 .col:nth-last-child(-n + 2){
  border-bottom: none;
}
.border-grid2 .col p{
  font-weight: 600;
}

.camera-box {
  border: 1px dashed #d6d6d6;
  border-radius: 4px;
  padding: 2px;
  width: 100%;
  min-height: 500px;
}

loading-item {
  min-width: 100px !important;
  margin-bottom: 0;
}

</style>
